.section-video{
    position: fixed;
    left: 0;
    right:0;
    top: 0;
    bottom: 0;
    background-color: aqua;

}
.youtube-player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.8); /* Optionnel: pour un fond semi-transparent */
    padding: 20px;
    border-radius: 10px;
    z-index: 1000; /* Assurez-vous que la vidéo est au-dessus des autres éléments */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .youtube-video {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .close-button {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #FF0000;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    z-index: 1;
  }
  
  .close-button:hover {
    background-color: #CC0000;
  }

.navbare{
  height: 100px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: rgb(228, 221, 221);
}

.navbare .navbaresection{
  height: 100%;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

}

.navbare .navbaresection .navbarlogo{
  width: 150px;
  height: 100px;
  display: flex;
  align-items: center;
}

.navbare .navbaresection .navbarlogo .image {
  max-width: 100%;
  object-fit: cover;
}

.navbare .navbaresection .link .nav-menue {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  gap: 30px;
}

.navbare .navbaresection .link .nav-menue a{
  text-decoration: none;
  text-align: none;
  list-style: none;
  color: rgb(83, 79, 79);
  font-weight: bold;
}


.navbare .navbaresection .link .nav-menue a:hover{
  color: brown;
}


.navbare .navbaresection .menu-icone{
  display: none;
}

@media (max-width: 768px){

  .navbare .navbaresection .link{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0, 0, 0);
    transform: translateX(100%);
    z-index: 20;
  }

  .navbare .navbaresection .link .nav-menue{
    flex-direction: column;
    margin-top: 40px;
    padding: 40px 0px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    justify-content: start;
  }

  .navbare .navbaresection .link .nav-menue a{
    color: white
  }

  .navbare .navbaresection .link .nav-menue a:hover{
    color: rgb(187, 183, 183);
    transition: 0.2s ease-in-out;
  }

  .navbare .navbaresection .menu-icone{
    display: flex;
    background-color: rgb(230, 230, 230);
    z-index: 1000;
    padding: 10px 8px;
    border-radius: 5px;
    cursor: pointer;
  }

  .navbare .navbaresection .link.open {
    transform: translateX(0%);
    left: 0;
  }


}




/* .navbar{
  height: 100px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 20;
  background-color: rgb(228, 221, 221);
} */

/* .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3f3b3b;
  padding: 5px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  z-index: 10;
} */
/* 
.navbar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 150px;
  height: 150px;
}

.navbar-logo img {
  min-width: 100%;
  object-fit: cover;
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
  color: #000;
  display: none;
}

.nav-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-item {
  padding: 15px;
  color: #fdfcfc;
  cursor: pointer;
}

.nav-item:hover {
  background-color: #000;
}

.nav-item:last-child {
  background-color: #b32940;
  color: #f8f4f4;
  border-radius: 20px;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

.nav-item:last-child:hover {
  background-color: #b32940;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  .menu-icon {
    display: block;
  }

  .nav-menu {
    display: none;
    flex-direction: column;
    background-color: #000;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 1000;
  }

  .nav-menu-visible {
    display: flex;
  }

  .nav-item {
    width: 100%;
    color: #000;
    padding: 15px;
  }

  .nav-item:hover {
    background-color: #000;
  }
}  */
.contact-page {
  padding: 20px;
  text-align: center;
  padding-top: 100px;

  /* background-image: url('../../medias/backgroundC.png'); */
  background-color:#666060;
  /* Path to your image */
  background-size: cover;
  /* Ensure the image covers the entire area */
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Prevent the image from repeating */
  min-height: 100vh;
  /* Ensure the background covers the full height of the page */
}

/* Contact Form Container */
.contact-form-container {
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  width: 300px;
  height: 450px;
  margin: 90px auto 10px;
}

.contact-form-container h1,
.contact-form-container p {
  color: #b32940;
  /* Matching the theme color */
}

.contact-form-container p {
  color: #fff;
  /* Set paragraph text color to white */
}

.contact-form {
  margin-top: 20px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: none;
  background-color: #333;
  color: #fff;
}

.contact-form button {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #b32940;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.mail {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #b32940;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #b32940;
}

/* Careers Container */
.careers-container {
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  width: 250px;
  height: 280px;
  float: left;
  /* Move to the left */
  margin-left: 20px;
  /* Space from the left */
  margin-top: -50px;
  /* Space from the top */
  text-align: left;
  /* Align text to the left */
}

.careers-container h2 {
  color: #b32940;
}

.career-card {
  margin-top: 20px;
}

.career-card img {
  display: block;
  margin: 0 auto;
  /* Center the image */
  width: 100px;
  margin-bottom: 20px;
}

.career-card h3 {
  color: #fff;
  margin: 10px 0;
}

/* Footer Styles */
.contact-footer {
  background-color: #333;
  color: white;
  text-align: center;

  width: 100%;
  /* Ensures the footer spans the full width */
  /* position: fixed; */
  /* bottom: 0; */
  /* Only relevant if using position: absolute */
  /* left: 0; */
  /* Ensure it starts from the left edge */
  /* right: 0; */
  /* Ensure it ends at the right edge */
  /* Aligns the footer at the bottom of the content */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);

  /* padding-top: 100px; */
  height: 200px;
}

.footer-links {
  margin-bottom: 10px;
}

.footer-links a {
  color: white;
  margin: 0 15px;
  /* Adjusts spacing between links */
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}
/* General App Styles */
.App {
  text-align: center;
  font-family: "Raleway", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: white;
}

.navbar-logo img {
  height: 40px;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.nav-menu {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-menu .nav-item a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.nav-menu .nav-item a:hover {
  text-decoration: underline;
}

/* Responsive Navbar */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav-menu {
    flex-direction: column;
    display: none;
  }

  .nav-menu.nav-menu-visible {
    display: flex;
  }
}
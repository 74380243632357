/* ServicesPage.css */

body {
  font-family: "Helvetica Neue", Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: #333;

  /* General container styling */
  .services {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    animation: slideIn 1.5s ease-in-out;
    /* background-image: url("../../medias/serviceBackground.jpg"); */
  }
}

/* Overview section */
.overview {
  background: linear-gradient(
    to right,
    #d43f3a,
    #c62828
  ); /* Darker shades of red */
  color: #fff;
  padding: 60px 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 40px;
}

.overview h1 {
  font-size: 3em;
  margin: 0;
  padding-bottom: 10px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.overview p {
  font-size: 1.2em;
  line-height: 1.6;
  max-width: 800px;
  margin: 20px auto 0;
}

/* Service categories section */
.service-categories {
  padding: 40px 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.service-categories h2 {
  font-size: 2.5em;
  color: #e53935;
  text-align: center;
  margin-bottom: 30px;
}

.category {
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s, transform 0.3s;
}

.category:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.category h3 {
  font-size: 1.8em;
  color: #e53935;
  margin-bottom: 10px;
}

.category p {
  font-size: 1.1em;
  color: #555;
  line-height: 1.6;
}

/* Service gallery section */
.service-gallery {
  padding: 40px 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.service-gallery h2 {
  font-size: 2em;
  color: #e53935;
  text-align: center;
  margin-bottom: 20px;
}

.gallery-images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.gallery-images img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.gallery-images img:hover {
  transform: scale(1.05);
}

/* Footer section */
.service-footer {
  padding: 20px;
  background-color: #333;
  color: white;
  text-align: center;
}

.footer-links {
  margin-bottom: 10px;
}

.footer-links a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.contact-info {
  margin-bottom: 10px;
}
